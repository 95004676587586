<template>
  <div class="home">
    <!-- 静态插槽 -->
    <Header></Header>
    <template>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, i) in bannerss" :key="i">
          <img :src="'http://website.yuangtech.com' + item" alt="" />
        </van-swipe-item>
      </van-swipe>
    </template>
    <template>
      <div class="center">
        <p>为什么选择七星</p>
        <p>WHY CHOOSE US</p>
        <div class="us">
          <img src="@/assets/qixingp/W_banner.jpg" alt="" />
          <h3>创意引领</h3>
          <span class="xian"></span>
          <p>
            我们专注特装、专注展览设计搭建、专注展位布展、专注会展搭建。因为我们专注，所以我们专业
          </p>
        </div>
        <div class="block">
          <h1>品质一流</h1>
          <span class="xian"></span>
          <p>让环保展具进入每一个展示空间</p>
        </div>
        <div class="block">
          <h1>绿色环保</h1>
          <span class="xian"></span>
          <p>服务就是生产力</p>
        </div>
        <div class="block">
          <h1>七星服务</h1>
          <span class="xian"></span>
          <p>执行安全第一管理理念</p>
        </div>
      </div>
    </template>
    <template>
      <div class="case">
        <p>绿色展具案例</p>
        <p>EXHIBITION CASE</p>
        <div class="ccde">
          <div class="pic">
            <img :src="'http://website.yuangtech.com' + green.cover" alt="" />
          </div>
          <div class="word">
            <p>{{ green.title }}</p>
            <p v-html="green.content"></p>
            <span @click="toDetail(green.id)">查看详情>></span>
          </div>
        </div>
        <img
          src="@/assets/qixingp/查看详情@2x.png"
          alt=""
          class="pics"
          @click="jump()"
        />
      </div>
    </template>
    <template>
      <div class="card">
        <p>展厅案例</p>
        <p>CLASSIC CASE</p>
        <el-carousel
          :interval="4000"
          type="card"
          height="9.743rem"
          width="18.318rem"
        >
          <el-carousel-item v-for="item in classic" :key="item.id">
            <img
              :src="'http://website.yuangtech.com' + item.cover"
              height="100%"
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </template>
    <template>
      <div class="picc">
        <p>服务流程</p>
        <p>SERVICE FLOW</p>
        <div class="proce">
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(11).png" alt="" />
            <p>沟通需求</p>
          </div>
          <img src="@/assets/qixingp/形状 1@2x.png" alt="" />
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(7).png" alt="" />
            <p>策划方案</p>
          </div>
          <img src="@/assets/qixingp/形状 1@2x.png" alt="" />
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(6).png" alt="" />
            <p>修改方案</p>
          </div>
          <img src="@/assets/qixingp/形状 1@2x.png" alt="" />
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(8).png" alt="" />
            <p>提交报价</p>
          </div>
          <img src="@/assets/qixingp/形状 1@2x.png" alt="" />
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(9).png" alt="" />
            <p>签订合同</p>
            <img src="@/assets/qixingp/形状 1@2x.png" alt="" class="xia" />
          </div>
        </div>
        <div class="proces">
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(4).png" alt="" />
            <p>项目总结</p>
          </div>
          <img src="@/assets/qixingp/形状 1@2x.png" alt="" />
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(5).png" alt="" />
            <p>竣工验收</p>
          </div>
          <img src="@/assets/qixingp/形状 1@2x.png" alt="" />
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(3).png" alt="" />
            <p>现场施工</p>
          </div>
          <img src="@/assets/qixingp/形状 1@2x.png" alt="" />
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(2).png" alt="" />
            <p>准备材料</p>
          </div>
          <img src="@/assets/qixingp/形状 1@2x.png" alt="" />
          <div>
            <img src="@/assets/qixingp/矢量智能对象@2x(10).png" alt="" />
            <p>报馆手续</p>
          </div>
        </div>
      </div>
    </template>
    <template>
      <div class="coop">
        <p>合作企业</p>
        <p>COOPERATIVE ENTERPRISE</p>
        <div class="logo">
          <img src="@/assets/qixingp/qy_logo@2x.png" alt="" />
          <!-- <img src="@/assets/qixingp/矩形 19@2x.png" alt="" />
          <img src="@/assets/qixingp/矩形 19@2x.png" alt="" />
          <img src="@/assets/qixingp/矩形 19@2x.png" alt="" />
          <img src="@/assets/qixingp/矩形 19@2x.png" alt="" />
          <img src="@/assets/qixingp/矩形 19@2x.png" alt="" />
          <img src="@/assets/qixingp/矩形 19@2x.png" alt="" />
          <img src="@/assets/qixingp/矩形 19@2x.png" alt="" />
          <img src="@/assets/qixingp/矩形 19@2x.png" alt="" />
          <img src="@/assets/qixingp/矩形 19@2x.png" alt="" />
          <img src="@/assets/qixingp/矩形 19@2x.png" alt="" /> -->
        </div>
      </div>
    </template>
    <template>
      <div class="design">
        <div class="inbb">
          <p class="p1">青岛七星会展专注于展览、展厅、设计施工。</p>
        </div>
        <div class="inbb">
          <p class="p2">客服电话：</p>
          <p class="p3">400-966-0622</p>
        </div>
        <div class="inbb">
          <p class="p4">微信立即咨询：</p>
          <img src="@/assets/qixingp/矩形 925@2x.png" alt="" />
        </div>
      </div>
    </template>
    <template>
      <div class="news">
        <p>新闻中心</p>
        <p>NEWS CENTER</p>
        <div class="new_s" @click="toDetails(new_s.id)">
          <img src="@/assets/qixingp/矩形 23.png" alt="" />
          <div class="optt">
            <div class="back_one">
              <p>{{ new_s.day }}</p>
              <p>{{ new_s.yeah_month }}</p>
            </div>
            <div class="back_two">
              <p>{{ new_s.title }}</p>
              <p v-html="new_s.content"></p>
            </div>
          </div>
          <div
            class="new_list"
            v-for="item in news"
            :key="item.id"
            @click="toDetails(item.id)"
          >
            <div class="back_one">
              <p>{{ item.day }}</p>
              <p>{{ item.yeah_month }}</p>
            </div>
            <div class="back_two">
              <p>{{ item.title }}</p>
              <p v-html="item.content"></p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template>
      <div class="sign">
        <p>设计需求:</p>
        <div class="wwwd">
          <div class="input">
            姓名：<input
              type="text"
              placeholder="请在这里输入姓名"
              v-model="name"
            />
          </div>
          <div class="input">
            电话：<input
              type="text"
              placeholder="请在这里输入电话"
              v-model="phone"
            />
          </div>
          <div class="input">
            邮箱：<input
              type="text"
              placeholder="请在这里输入邮箱"
              v-model="email"
            />
          </div>
          <div class="input">
            需求：
            <textarea
              rows="5"
              cols="20"
              placeholder="请在这里输入需求"
              v-model="content"
            ></textarea>
          </div>
        </div>
        <span>发布</span>
      </div>
    </template>
    <bottom></bottom>
  </div>
</template>

<script>
import Header from "@/components/header1s.vue";
import bottom from "@/components/bottoms.vue";
import getCate from "@/utils/getCate";
export default {
  name: "index_p",
  components: { bottom, Header },
  data() {
    return {
      pages: 6,
      tempPage: 2,
      isV: true,
      page: 1,
      name: "",
      phone: "",
      email: "",
      content: "",
      bannerss: [],
      green: [],
      classic: [],
      news: [],
      new_s: {},
      pag3: [],
      pag4: [],
      pag5: [],
      typeList: [],
      config: {
        // 自动播放
        autoPlay: false,
        //  循环播放
        loop: false,
        // 切换间隔
        interval: 1000,
      },
    };
  },
  created() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      // window.location.href = "/index";
    } else {
      window.location.href = "/index_p"; //跳转到电脑
    }
    //自动加载banner方法
    this.banner();
    this.cases();
    this.newss();
  },

  methods: {
    toDetail(id) {
      this.$router.push({
        path: "/exhibitionsDetail_s",
        query: { item: id },
      });
    },
    toDetails(id) {
      this.$router.push({ path: "/newsDetails_s", query: { item: id } });
    },
    jump() {
      this.$router.push({ path: "/exhibitions_s" });
    },
    banner() {
      const that = this;
      const data = {};
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/qx_banner_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            console.log("ress", res.data);
            that.bannerss = res.data.data;
            // "?v=" +
            // Math.round(Math.random() * 5);
            // that.bannerss.push(url);
            console.log("that.bannerss", that.bannerss);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cases() {
      const that = this;
      const data = {};
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/qx_home_case", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res.data.data, "222");
            that.green = res.data.data.green[0];
            console.log("that.green", that.green);
            that.classic = res.data.data.classic;
            console.log("that.classic", that.classic);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    release() {
      const that = this;
      const data = {
        name: that.name,
        phone: that.phone,
        email: that.email,
        content: that.content,
      };
      // cate_id: that.tabIndex,
      if (
        that.name == "" &&
        that.phone == "" &&
        that.email == "" &&
        that.content == ""
      ) {
        this.$message({
          message: "您尚未填写完整！",
          type: "warning",
        });
      } else {
        that.$http
          .postApi("/qx_need_add", data)
          .then((res) => {
            if (res.data.code === 200) {
              that.name = "";
              that.phone = "";
              that.email = "";
              that.content = "";
              this.$message({
                message: "发布成功",
                type: "success",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    newss() {
      const that = this;
      const data = {
        page: 1,
        limit: 8,
      };
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/qx_news_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res.data.data, "一二三四");
            that.news = res.data.data.list;
            console.log("that.news", that.news);
            that.new_s = res.data.data.list[0];
            console.log("that.new_s", that.new_s);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fillterImg(data) {
      let informationArticle = data
        .replace(/<\/?(img)[^>]*>/gi, "")
        .replace(/<\/?[^>]*>/g, "");
      return informationArticle;
    },
    onRollEnd(page) {
      console.log("当前页面为", page);
    },
    onPointerMouseover(page) {
      page.event.target.title = "1";
    },
    toPage() {
      this.page = this.tempPage;
    },
    pushRouter(item, id) {
      this.$router.push({ path: item, query: { item: id } });
    },
    getType() {
      const that = this;
      getCate(1).then((res) => {
        if (res.code === 200) {
          console.log(res);
          that.typeList = res.data;
        }
      });
    },
    getpag3() {
      const that = this;
      const data = {
        page: 1,
        limit: 8,
        module_id: 1,
        cate_id: that.tabIndex,
      };
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.pag3 = res.data.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getpag4() {
      const that = this;
      const data = {
        page: 1,
        limit: 4,
        module_id: 2,
      };
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.pag4 = res.data.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getpag5() {
      const that = this;
      const data = {
        page: 1,
        limit: 3,
        module_id: 5,
      };
      // cate_id: that.tabIndex,
      that.$http
        .postApi("/data_list", data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res);
            that.pag5 = res.data.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tolist(url, id) {
      this.$router.push({ path: url, query: { item: id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  /deep/.el-button {
    width: 99px;
    height: 30px;
    border: 1px solid #cfcfcf;
    padding: 7px 20px;
  }
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  height: 10rem;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
  img {
    width: 100%;
    height: 100%;
  }
}
.center {
  width: 18.31875rem;
  margin: 0 auto;
  // background: red;
  margin-top: 1.6875rem;
  p {
    &:nth-child(1) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0059ab;
      text-align: center;
    }
    &:nth-child(2) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #dadfe7;
      text-align: center;
    }
  }
  .us {
    width: 100%;
    position: relative;
    height: 11.0875rem;
    margin-bottom: 0.1875rem;
    img {
      width: 100%;
      height: 100%;
    }
    h3 {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      bottom: 3.1875rem;
      left: 1.3125rem;
    }
    .xian {
      display: block;
      width: 16px;
      height: 1px;
      background: #ffffff;
      position: absolute;
      left: 1.3125rem;
      bottom: 3rem;
    }
    p {
      display: block;
      width: 16.5rem;
      font-size: 0.45rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      left: 21px;
      bottom: 1rem;
    }
  }
}
.block {
  width: 100%;
  height: 5.193rem;
  background: url("~@/assets/qixingp/矩形 5 拷贝 4.png");
  margin-bottom: 0.125rem;
  h1 {
    font-size: 1.125rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #f5f5f5;
    text-align: center;
    padding-top: 1rem;
  }
  .xian {
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 1px;
    background: #ffffff;
    margin-top: 0.25rem;
  }
  p {
    font-size: 0.5rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #c9caca;
    text-align: center;
    margin-top: 0.25rem;
  }
}
.case {
  width: 100%;
  background: url("~@/assets/qixingp/矩形 9 拷贝.png");
  background-size: 100% 100%;
  height: 37.375rem;
  margin-top: 1.5rem;
  padding-top: 1.9375rem;
  p {
    &:nth-child(1) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
    }
    &:nth-child(2) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #dadfe7;
      text-align: center;
    }
  }
  .ccde {
    margin: 0 auto;
    width: 18.318rem;
    height: 24.856rem;
    margin-top: 1.375rem;
    // background: #fff;
    .pic {
      width: 18.318rem;
      height: 14.681rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .word {
    padding: 1.5rem 1rem 2rem 1.5625rem;
    background: #fff;
    p {
      &:nth-child(1) {
        display: block;
        border-left: 2px solid #0059ab;
        text-align: left;
        margin-left: -0.5625rem;
        padding-left: 0.5rem;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #0059ab;
      }
      &:nth-child(2) {
        display: block;
        width: 15.75rem;
        height: 4.1875rem;
        overflow: hidden;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #595757;
        text-align: left;
        margin: 1.0625rem 0;
        text-indent: 2em;
        ::v-deep img {
          display: none;
        }
      }
    }
    span {
      background: url("~@/assets/qixingp/圆角矩形 1 拷贝.png") no-repeat;
      background-position: right;
      background-size: cover;
      width: 5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      display: block;
      text-align: center;
      font-size: 9px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      float: right;
    }
  }
}
.pics {
  display: block;
  width: 6.625rem;
  height: 1.95rem;
  margin: 0 auto;
  margin-top: 2.625rem;
}
.card {
  margin-top: 1.5rem;
  p {
    &:nth-child(1) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0059ab;
      text-align: center;
    }
    &:nth-child(2) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #dadfe7;
      text-align: center;
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/deep/ .el-carousel__button {
  // 指示器按钮
  width: 0px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}
.picc {
  width: 100%;
  height: 15rem;
  background: url("~@/assets/qixingp/矩形 16.png");
  background-size: cover;
  padding-bottom: 2.125rem;
  p {
    &:nth-child(1) {
      padding-top: 1.5rem;
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #fefefe;
      text-align: center;
    }
    &:nth-child(2) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #2171ba;
      text-align: center;
    }
  }
}
.proce {
  display: flex;
  align-items: center;
  width: 18.3125rem;
  margin: 0 auto;
  position: relative;
  margin-top: 1.1875rem;
  p {
    font-size: 7px !important;
    font-family: Source Han Sans CN !important;
    font-weight: 500 !important;
    color: #ffffff !important;
  }
  img {
    width: 1.906rem;
    height: 1.6875rem;
    margin-left: 0.875rem;
    &:nth-child(1) {
      margin-left: 0;
    }
    &:nth-child(2n) {
      width: 0.475rem;
      height: 0.431rem;
      line-height: 0.431rem;
    }
  }
  .xia {
    width: 0.475rem;
    height: 0.431rem;
    line-height: 0.431rem;
    transform: rotate(90deg);
    position: absolute;
    bottom: -0.7rem;
    right: 1.2rem;
    // float: right;
    // margin-top: 0.5rem;
    // margin-right: 20%;
  }
}
.proces {
  display: flex;
  align-items: center;
  width: 18.3125rem;
  margin: 0 auto;
  margin-top: 1.3125rem;

  p {
    font-size: 0.4rem !important;
    font-family: Source Han Sans CN !important;
    font-weight: 500 !important;
    color: #ffffff !important;
  }
  img {
    width: 1.906rem;
    height: 1.6875rem;
    margin-left: 0.875rem;
    &:nth-child(1) {
      margin-left: 0;
    }
    &:nth-child(2n) {
      width: 0.475rem;
      height: 0.431rem;
      line-height: 0.431rem;
      transform: rotateY(180deg);
    }
  }
}

.coop {
  padding-top: 1.6875rem;
  padding-bottom: 2.0625rem;
  p {
    &:nth-child(1) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0059ab;
      text-align: center;
    }
    &:nth-child(2) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #dadfe7;
      text-align: center;
    }
  }
  .logo {
    width: 18.3125rem;
    margin: 0 auto;
    margin-top: 1rem;
    img {
      width: 100%;
    }
    // img {
    //   width: 3.075rem;
    //   height: 1.518rem;
    //   margin-right: 0.7rem;
    //   &:nth-child(5) {
    //     margin-right: 0;
    //   }
    //   &:nth-child(10) {
    //     margin-right: 0;
    //   }
    // }
  }
}
.design {
  width: 100%;
  height: 4.631rem;
  background: url("~@/assets/qixingp/矩形 16.png");
  .inbb {
    display: inline-block;
    &:nth-child(1) {
      width: 11rem;
      margin-left: 0.5rem;
      margin-top: 1.5rem;
    }
    &:nth-child(2) {
      width: 5.75rem;
      margin-left: 0.3rem;
      margin-top: 1.5rem;
    }
    &:nth-child(3) {
      float: right;
      margin-top: 0.5rem;
    }
    .p1 {
      font-size: 0.625rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }
    .p2 {
      font-size: 0.4375rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    .p3 {
      font-size: 0.625rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    .p4 {
      font-size: 5px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    img {
      width: 2.1875rem;
      height: 2.1875rem;
      // margin-left: 1rem;
      margin-top: 0.2rem;
    }
  }
}
.news {
  width: 18.3625rem;
  margin: 0 auto;
  padding-top: 1.6875rem;
  padding-bottom: 2.0625rem;
  p {
    &:nth-child(1) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0059ab;
      text-align: center;
    }
    &:nth-child(2) {
      font-size: 1.125rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #dadfe7;
      text-align: center;
    }
  }
  .new_s {
    width: 18.318rem;
    margin: 0 auto;
    background-color: #000;
    opacity: 0.7;
    .optt {
      .back_one {
        display: inline-block;
        width: 3rem;
        height: 2.768rem;
        margin-left: 0.625rem;
        p {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          &:nth-child(2) {
            font-size: 6px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
      .back_two {
        display: inline-block;
        // width: 15rem;
        height: 2.768rem;
        margin-left: 0.625rem;
        p {
          font-size: 8px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          text-align: left;
          &:nth-child(2) {
            font-size: 7px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ececec;
            text-align: left;
            ::v-deep img {
              display: none;
            }
          }
        }
      }
    }
    .new_list {
      padding-top: 0.5rem;
      border-bottom: 1px solid #dbdbdb;
      background-color: #f7f8f8;
      &:hover {
        background-color: #1364af;
        color: #fff;
      }
      &:hover .back_one p {
        color: #fff !important  ;
      }
      &:hover .back_two p {
        color: #fff !important  ;
      }
      .back_one {
        display: inline-block;
        width: 3rem;
        height: 2.768rem;
        margin-left: 0.625rem;
        p {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #595757;
          &:nth-child(2) {
            font-size: 6px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #595757;
          }
        }
      }
      .back_two {
        display: inline-block;
        // width: 15rem;
        height: 2.768rem;
        margin-left: 0.625rem;
        p {
          font-size: 8px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #000;
          text-align: left;
          &:nth-child(2) {
            font-size: 7px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #595757;
            text-align: left;
            ::v-deep img {
              display: none;
            }
          }
        }
      }
    }
  }
}
.sign {
  width: 18.3625rem;
  margin: 0 auto;
  padding-top: 1.6875rem;
  padding-bottom: 2.0625rem;
  height: 17.5rem;
  p {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #1364af;
    text-align: center;
  }
  .wwwd {
    .input {
      margin-top: 0.75rem;
      input {
        width: 15rem;
        height: 1.343rem;
        border: 0.0625rem solid #4e8ac3;
      }
      textarea {
        width: 15rem;
        border: 0.0625rem solid #4e8ac3;
        float: right;
        margin-right: 0.3rem;
      }
    }
  }
  span {
    width: 5.4125rem;
    height: 1.818rem;
    line-height: 1.818rem;
    display: block;
    margin: 0 auto;
    text-align: center;
    border-radius: 10px;
    background-color: #0059ab;
    color: #fff;
    margin-top: 7rem;
  }
}
</style>
